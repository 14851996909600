.rmc-drawer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
}
.rmc-drawer-sidebar {
  z-index: 2;
  position: absolute;
  transition: transform 0.3s ease-out;
  will-change: transform;
  overflow-y: auto;
}
.rmc-drawer-draghandle {
  z-index: 1;
  position: absolute;
  background-color: rgba(50, 50, 50, 0.1);
}
.rmc-drawer-overlay {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease-out;
  background-color: rgba(0, 0, 0, 0.3);
}
.rmc-drawer-content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  transition: left 0.3s ease-out, right 0.3s ease-out, top 0.3s ease-out, bottom 0.3s ease-out;
}
.rmc-drawer.rmc-drawer-left .rmc-drawer-sidebar,
.rmc-drawer.rmc-drawer-right .rmc-drawer-sidebar,
.rmc-drawer.rmc-drawer-left .rmc-drawer-draghandle,
.rmc-drawer.rmc-drawer-right .rmc-drawer-draghandle {
  top: 0;
  bottom: 0;
}
.rmc-drawer.rmc-drawer-left .rmc-drawer-draghandle,
.rmc-drawer.rmc-drawer-right .rmc-drawer-draghandle {
  width: 20px;
  height: 100%;
}
.rmc-drawer.rmc-drawer-top .rmc-drawer-sidebar,
.rmc-drawer.rmc-drawer-bottom .rmc-drawer-sidebar,
.rmc-drawer.rmc-drawer-top .rmc-drawer-draghandle,
.rmc-drawer.rmc-drawer-bottom .rmc-drawer-draghandle {
  left: 0;
  right: 0;
}
.rmc-drawer.rmc-drawer-top .rmc-drawer-draghandle,
.rmc-drawer.rmc-drawer-bottom .rmc-drawer-draghandle {
  width: 100%;
  height: 20px;
}
.rmc-drawer.rmc-drawer-left .rmc-drawer-sidebar {
  left: 0;
  transform: translateX(-100%);
}
.rmc-drawer-open.rmc-drawer.rmc-drawer-left .rmc-drawer-sidebar {
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.15);
}
.rmc-drawer.rmc-drawer-left .rmc-drawer-draghandle {
  left: 0;
}
.rmc-drawer.rmc-drawer-right .rmc-drawer-sidebar {
  right: 0;
  transform: translateX(100%);
}
.rmc-drawer-open.rmc-drawer.rmc-drawer-right .rmc-drawer-sidebar {
  box-shadow: -2px 2px 4px rgba(0, 0, 0, 0.15);
}
.rmc-drawer.rmc-drawer-right .rmc-drawer-draghandle {
  right: 0;
}
.rmc-drawer.rmc-drawer-top .rmc-drawer-sidebar {
  top: 0;
  transform: translateY(-100%);
}
.rmc-drawer-open.rmc-drawer.rmc-drawer-top .rmc-drawer-sidebar {
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.15);
}
.rmc-drawer.rmc-drawer-top .rmc-drawer-draghandle {
  top: 0;
}
.rmc-drawer.rmc-drawer-bottom .rmc-drawer-sidebar {
  bottom: 0;
  transform: translateY(100%);
}
.rmc-drawer-open.rmc-drawer.rmc-drawer-bottom .rmc-drawer-sidebar {
  box-shadow: 2px -2px 4px rgba(0, 0, 0, 0.15);
}
.rmc-drawer.rmc-drawer-bottom .rmc-drawer-draghandle {
  bottom: 0;
}
